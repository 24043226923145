<template>
  <div class="page-wrapper">

    <header class="main-header">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18663562247">1-866-356-2247</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a :href="$t('site.renouvellement-link-2')" target="_blank" class="cta red">{{ $t('site.achetez-pass') }}</a>
      </div>
      <a href="tel:18663562247" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a :href="$t('site.renouvellement-link-2')" target="_blank" class="cta red">{{ $t('site.achetez-pass') }}</a>
      </div>
    </header>

    <section class="hero renouvellement-dink">
      <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/pastille-numero-1-fr.svg" class="pastille" alt="#1 dans l'est par ski magazine" />
      <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/pastille-numero-1-en.svg" class="pastille" alt="#1 in theeast by ski magazine" />
      <div class="img-mobile"></div>
      <div class="deal-wrap" data-inview="slideLeft" data-delay="100">
        <div class="top">
          <img src="@/assets/img/curve-green.svg" alt="Curve" />
          <div class="data"><h2 class="big-title" v-html="$t('site.slogan')"></h2></div>
        </div>
        <div class="bottom">
          <h1 class="small-title full" v-html="$t('site.renouvellement-hero-title')"></h1>
          <div class="deal">
            <h2 class="small-title" v-html="$t('site.renouvellement-heb-hero')"></h2>
            <a :href="$t('site.renouvellement-link-2')" target="_blank" class="cta blanc-bleu no-margin-top">{{ $t('site.achetez-short') }}</a>
            <a v-scroll-to:-80 href="#offre-wrap" class="regular-text">{{ $t('site.show-details') }}</a>
          </div>
          <div class="deal">
            <h2 class="small-title" v-html="$t('site.renouvellement-ski-hero')"></h2>
            <a :href="$t('site.renouvellement-link')" target="_blank" class="cta blanc-bleu no-margin-top">{{ $t('site.achetez-short') }}</a>
            <a v-scroll-to:-80 href="#offre-wrap" class="regular-text">{{ $t('site.show-details') }}</a>
          </div>
          <p class="cut-off">{{ $t('site.renouvellement-cut') }}</p>
        </div>
      </div>
      <div class="snow-background">
        <img src="@/assets/img/hero-snow-2.png" class="img-snow" alt="Snow" />
      </div>
    </section>

    <section id="ancre-privileges" class="privileges tonik-dink" data-inview>
      <div class="title">
        <h3 class="big-title" data-inview="fadeInUp" data-delay="300" v-html="$t('site.privilege-card-title')"></h3>
      </div>
      <div class="price-card" data-inview="fadeInUp" data-delay="400">
        <div class="price" v-html="$t('site.privilege-price-1')"></div>
        <div class="price" v-html="$t('site.privilege-price-2')"></div>
      </div>
      <div class="grid-card" data-inview="fadeInUp" data-delay="500">
        
        <div v-for="(item, index) in privileges" class="card" @click.prevent="showsidepanel((index + 1))" :key="index">
          <div :class="'img privilege-' + (index + 1)"></div>
          <div class="data">
            <p class="regular-text">{{ item.title }}</p>
            <img src="@/assets/img/plus.svg" alt="Plus">
          </div>
        </div>

      </div>
      <div class="cta-wrap" data-inview="fadeInUp" data-delay="600" id="assurance">
        <a :href="$t('site.privilege-cta-link')" target="_blank" class="cta red">{{ $t('site.privilege-cta-title') }}</a>
      </div>
      <a v-if="$route.meta.lang === 'fr'" href="/hiver/2122/renouvellement/terms-privileges/" data-inview="fadeInUp" data-delay="600" class="regular-text modalite-privileges">{{ $t('site.modalites') }}</a>
      <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2122/renouvellement/terms-privileges/" data-inview="fadeInUp" data-delay="600" class="regular-text modalite-privileges">{{ $t('site.modalites') }}</a>
      
      <div class="title space-top">
        <div data-inview="fadeInUp" data-delay="800">
          <lottie-player
            background="transparent"
            speed="1"
            autoplay
            :data-src="'/json/garantie.json'"
            class="lottie-icon"
          >
          </lottie-player>
        </div>
        <h3 class="big-title" data-inview="fadeInUp" data-delay="900" v-html="$t('site.garantie-title')"></h3>
        <div class="cta-wrap" data-inview="fadeInUp" data-delay="1000">
          <a href="#" target="_blank" class="cta red" @click.prevent="showwarrantypanel">{{ $t('site.more') }}</a>
        </div>
      </div>
    </section>

    <section class="video-wrap">
      <h3 class="big-title space-bottom" data-inview="fadeInUp" data-delay="300" v-html="$t('site.renouvellement-best-1')"></h3>
      <div class="video renouvellement-dink" data-inview="fadeInUp" data-delay="400">
        <a :href="$t('site.renouvellement-video-link-dink')" class="glightbox"><img src="@/assets/img/play.svg" class="play" alt="play" /></a>
      </div>
    </section>

    <section class="offre-wrap renouvellement-dink" id="offre-wrap">

      <div class="img-mobile"></div>

      <div class="offre" data-inview="slideLeft" data-delay="300">
        <div class="top">
          <img src="@/assets/img/curve-red.svg" alt="Curve">
        </div>
        <div class="bottom">
          <div class="toggle">
            <p :class="{ actif: toggle }" @click.prevent="table1">{{ $t('site.adulte-2') }}</p>
            <p :class="{ actif: !toggle }" @click.prevent="table2">{{ $t('site.enfant-2') }}</p>
          </div>

          <div class="content" :class="{ actif: toggle }" id="tab1-link">
            <div v-html="$t('site.renouvellement-dink-data')"></div>
            <a :href="$t('site.renouvellement-link-2')" target="_blank" class="cta blanc-rouge">{{ $t('site.achetez-pass') }}</a>
            <a v-if="$route.meta.lang === 'fr'" href="/hiver/2122/renouvellement/terms-renouvellement/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
            <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2122/renouvellement/terms-renouvellement/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
          </div>

          <div class="content" :class="{ actif: !toggle }" id="tab2-link">
            <div v-html="$t('site.renouvellement-fam-data')"></div>
            <a :href="$t('site.renouvellement-link-2')" target="_blank" class="cta blanc-rouge">{{ $t('site.achetez-pass') }}</a>
            <a v-if="$route.meta.lang === 'fr'" href="/hiver/2122/renouvellement/terms-renouvellement/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
            <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2122/renouvellement/terms-renouvellement/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
          </div>

        </div>
      </div>
    </section>

    <section class="cross-sell split">
      <div class="bloc-half" data-inview="fadeInUp" data-delay="400">
        <div class="img">
          <img src="@/assets/img/ecoresponsabilite-fr-small.jpg" v-if="$route.meta.lang === 'fr'" alt="Écoresponsabilité Tremblant">
          <img src="@/assets/img/ecoresponsabilite-en-small.jpg" v-else-if="$route.meta.lang === 'en'" alt="Écoresponsabilité Tremblant">
        </div>
        <div class="data">
          <h3 class="medium-title">{{ $t('site.eco-title') }}</h3>
          <p class="regular-text" v-html="$t('site.eco-data')"></p>
          <a :href="$t('site.eco-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.eco-cta') }}</a>
        </div>
      </div>

      <div class="bloc-half" data-inview="fadeInUp" data-delay="400">
        <div class="img">
          <img src="@/assets/img/ecole-neige-dink.jpg" alt="École sur neige Tremblant" />
        </div>
        <div class="data">
          <h3 class="medium-title" v-html="$t('site.ecole-title')"></h3>
          <p class="regular-text" v-html="$t('site.ecole-data')"></p>
          <a :href="$t('site.ecole-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.ecole-cta') }}</a>
        </div>
      </div>

      <!-- <div class="bloc-half" data-inview="fadeInUp" data-delay="400">
        <div class="img">
          <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/ecoresponsabilite-fr-small.jpg" alt="Écoresponsabilité Tremblant" />
          <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/ecoresponsabilite-en-small.jpg" alt="Écoresponsabilité Tremblant" />
        </div>
        <div class="data">
          <h3 class="medium-title">{{ $t('site.eco-title') }}</h3>
          <p class="regular-text" v-html="$t('site.eco-data')"></p>
          <a :href="$t('site.eco-link')" target="_blank" class="cta blanc-bleu">{{ $t('site.eco-cta') }}</a>
        </div>
      </div> -->

    </section>

    <news-letter></news-letter>

    <footer-bar></footer-bar>

    <!-- <div class="logo-partenaire" v-if="$route.meta.lang === 'en'">
      <a href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div> -->

    <!-- <div class="note-mesures">
      <p class="regular-text" v-html="$t('site.mesures-note')">-</p>
    </div> -->

    <div class="newsletter-button" :class="{ active: newsletter, force: newsletterforce }">
      <a v-scroll-to:-80 href="#newsletter" class="wrap" @click.prevent="hidenewsletter">
        <img src="@/assets/img/mail.svg" alt="Mail" />
        <p>{{ $t('site.news-alert') }}</p>
      </a>
      <img src="@/assets/img/close-blue.svg" class="close" alt="close" @click.prevent="hidenewsletter" />
    </div>

    <div class="overlay-side-panel" :class="{ show: sidepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidesidepanel">

      <div class="side-panel" v-if="privileges && privileges[(sidepanel - 1)]">
        <div class="img dink" :style="'background-image: url(' + privileges[(sidepanel - 1)].image + ')'" @click.stop>
          <div class="arrow-wrap prev" @click.prevent.stop="goToPrevPanel()">
            <img src="@/assets/img/prev.svg" alt="prev" />
          </div>
          <div class="arrow-wrap next" @click.prevent.stop="goToNextPanel()">
            <img src="@/assets/img/next.svg" alt="next" />
          </div>
        </div>
        <div class="data" v-html="privileges[(sidepanel - 1)].details"></div>
      </div>
      <div class="opacity" @click.prevent="hidesidepanel"></div>
    </div>

    <div class="overlay-side-panel" :class="{ show: warrantypanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidewarrantypanel">
      <div class="side-panel">
        <div class="img warranty"></div>
        <div class="data" v-html="$t('site.garantie-data')"></div>
      </div>
      <div class="opacity" @click.prevent="hidewarrantypanel"></div>
    </div>

  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'
import Flickity from 'vue-flickity'
import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker, Flickity },
  head() {
    return {
      title: {
        inner: this.$t(`site.renouvellement-meta-title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site.renouvellement-meta-title`) },
        {
          name: 'description',
          content: this.$t(`site.renouvellement-meta-desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site.renouvellement-meta-title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site.renouvellement-meta-desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site.renouvellement-meta-title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site.renouvellement-meta-desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/img/bighit/avt-dink/hero.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/img/bighit/avt-dink/hero.jpg',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      sidepaneldetails: false,
      politiquepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      newsletter: false,
      warrantypanel: false,
      newsletterforce: false,
      time1: new Date(),
      time2: null,
      privileges: [],
      flickityOptions: {
        initialIndex: 0,
        draggable: true,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        freeScroll: false,
        contain: true,
        cellAlign: 'left',
      },
      activityDink: [],
      destination: 'cad',
    }
  },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.hash) {
            setTimeout(() => {
              if ($route.hash === '#privileges') {
                const $target = document.querySelector('#ancre-privileges')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                return
              }
              if ($route.hash === '#assurance') {
                const $target = document.querySelector('#assurance')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                return
              }
              if ($route.hash === '#tab2') {
                this.table2()
              }
              const $target = document.querySelector('#offre-wrap')
              $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }, 755)
          }
          if ($route.query && $route.query.destination === 'usa') {
            this.destination = 'usa'
          }
        }
      },
      immediate: true,
    },
  },
  computed: mapState('app', ['appTitle']),
  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }

    this.activityDink = this.$t('activityDink')
    setTimeout(() => {
      this.$refs.flickity.next()
      this.$refs.flickity.previous()
    }, 1)

    this.privileges = this.$t('privileges')
  },
  created() {
    window.addEventListener('scroll', () => {
      this.snowscroll()
      this.actionnewsletter()
    })
  },
  methods: {
    table1() {
      this.toggle = true
    },
    table2() {
      this.toggle = false
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    hidenewsletter() {
      this.newsletterforce = true
    },
    actionnewsletter() {
      const st = document.documentElement.scrollTop

      if (st >= 400 && !this.newsletterforce) {
        this.newsletter = true
      } else {
        this.newsletter = false
      }
    },
    showsidepanel() {
      if (this.sidepanel) {
        this.sidepanel = false
      } else {
        this.sidepanel = true
      }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    showsidepaneldetails(index) {
      this.sidepaneldetails = index
    },
    hidesidepaneldetails() {
      this.sidepaneldetails = false
    },
    goToPrevPanel() {
      if (this.sidepanel <= 1) {
        this.sidepanel = this.privileges.length
        return
      }
      this.sidepanel -= 1
    },
    goToNextPanel() {

      if (this.sidepanel >= this.privileges.length) {
        this.sidepanel = 1
        return
      }

      this.sidepanel += 1
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${-150 + st / 2}px`
    },
    showpolitiquepanel() {
      if (this.politiquepanel) {
        this.politiquepanel = false
      } else {
        this.politiquepanel = true
      }
    },
    hidepolitiquepanel() {
      this.politiquepanel = false
    },
    showwarrantypanel() {
        if(this.warrantypanel) {
          this.warrantypanel = false
        } else {
          this.warrantypanel = true
        }
    },
    hidewarrantypanel() {
      this.warrantypanel = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
